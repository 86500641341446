import type { Dictionary } from '@types'

export const BENEFITS_CAPTIONS = {
  HEALTH_INSURANCE: 'Health Insurance',
  RETIREMENT_PLAN: 'Retirement Plan',
  PAID_TIME_OFF: 'Paid Time Off',
  LIFE_INSURANCE: 'Life Insurance',
  DISABILITY_INSURANCE: 'Disability Insurance',
  TUITION_REIMBURSEMENT: 'Tuition Reimbursement',
  EMPLOYEE_ASSISTANCE: 'Employee Assistance',
  FLEXIBLE_SPENDING_ACCOUNT: 'Flexible Spending Account',
  EMPLOYEE_DISCOUNTS: 'Employee Discounts',
  WELLNESS_PROGRAM: 'Wellness Program',
  SIGN_ON_BONUS: 'Sign On Bonus',
}

export const EXPERIMENT_COOKIES_DEFAULT_OPTIONS = {
  maxAge: 24 * 60 * 60,
  path: '/',
  secure: true,
}

export const GOOGLE_CRAWLERS: string[] = [
  'Mozilla/5.0 (Windows NT 5.1; rv:11.0) Gecko Firefox/11.0 (via ggpht.com GoogleImageProxy)',
  'Mozilla/5.0 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'Googlebot-Image/1.0',
  'Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/56.0.2924.87 Safari/537.36 Google (+https://developers.google.com/+/web/snippet/)',
  'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2272.96 Mobile Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
  'Googlebot/2.1 (+http://www.google.com/bot.html)',
]

export const basePath =
  process.env.NEXT_PUBLIC_BASE_PATH === '/' || process.env.NEXT_PUBLIC_BASE_PATH === null
    ? ''
    : (process.env.NEXT_PUBLIC_BASE_PATH as string).replace(/\/+$/, '')

export const dictionaries: Record<'uk' | 'us', Dictionary> = {
  uk: {
    currency: '£',
    resume: 'CV',
    calendlyLink: 'https://calendly.com/hello-6515/30min',
    jobs: '500K',
    exampleLocation: 'london',
    country: 'United Kingdom',
    countryCodeISO: 'gb',
    phonePrefix: '+44',
    zipCode: 'postcode',
  },
  us: {
    currency: '$',
    resume: 'resume',
    calendlyLink: 'https://calendly.com/hello-6515/sonicjobs-candidate-feedback-uk-30-minute-meeti-clone',
    jobs: '1M',
    exampleLocation: 'new york',
    country: 'United States',
    countryCodeISO: 'us',
    phonePrefix: '+1',
    zipCode: 'ZIP code',
  },
}

export const enableSmartApply = {
  us: process.env.NEXT_PUBLIC_ENABLE_SMART_APPLY_US === 'true',
  uk: process.env.NEXT_PUBLIC_ENABLE_SMART_APPLY_UK === 'true',
}
