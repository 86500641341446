'use client'

import dynamic from 'next/dynamic'

import Link from '@components/link'
import useIsLoggedIn from '@hooks/user/useIsLoggedIn'

import styles from './profile.module.scss'

const ProfileBtn = dynamic(() => import('@components/layout/layout/navbar/profile/profile-btn'), { ssr: false })

export default function Profile(): JSX.Element {
  const { isLoggedIn } = useIsLoggedIn()

  if (isLoggedIn) {
    return <ProfileBtn />
  }

  return (
    <Link href="/login" className={styles.link}>
      Login
    </Link>
  )
}
