'use client'

import { useState } from 'react'

import dynamic from 'next/dynamic'
import Image from 'next/image'

import Button from '@components/buttons/button'
import Link from '@components/link'

import menuIcon from '@images/navbar/icon_burger.svg'
import searchIcon from '@images/navbar/icon_field-search.svg'

import styles from './links.module.scss'

const Menu = dynamic(() => import('@components/layout/layout/menu'), { ssr: false })
const Search = dynamic(() => import('@components/layout/layout/search'), { ssr: false })
export type NavbarProps = {
  variant?: 'default' | 'simple'
}

function Links({ variant }: NavbarProps): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)

  if (variant === 'default') {
    return (
      <div className={styles.links}>
        <ul className={styles['links-desktop']}>
          <li className={styles['link-item']}>
            <Button variant="simple-text" size="full" onClick={(): void => setIsSearchOpen(true)}>
              <Image src={searchIcon} alt="Search icon" width={20} height={20} />
              Search
            </Button>
          </li>
          <li className={styles['link-item']}>
            <Link href="/ai-agent">AI Agent</Link>
          </li>
          <li className={styles['link-item']}>
            <Link href="/jobs">Browse Jobs</Link>
          </li>
          <li className={styles['link-item']}>
            <Button variant="simple-text" size="full" onClick={(): void => setIsMenuOpen(true)}>
              <Image src={menuIcon} alt="Search icon" width={20} height={20} />
              More
            </Button>
          </li>
        </ul>
        <ul className={styles['links-mobile']}>
          <li className={styles['link-item']}>
            <Button variant="simple-text" size="full" onClick={(): void => setIsSearchOpen(true)}>
              <Image src={searchIcon} alt="Search icon" width={20} height={20} />
            </Button>
          </li>
          <li className={styles['link-item']}>
            <Button variant="simple-text" size="full" onClick={(): void => setIsMenuOpen(true)}>
              <Image src={menuIcon} alt="Search icon" width={20} height={20} />
            </Button>
          </li>
        </ul>
        <Search isOpen={isSearchOpen} close={(): void => setIsSearchOpen(false)} />
        <Menu isOpen={isMenuOpen} close={(): void => setIsMenuOpen(false)} />
      </div>
    )
  }
  return <></>
}

export default Links
